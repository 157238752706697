import {UserDataInfo} from '@/plugins/api'


const userData = new UserDataInfo();

export default class NoteModel {
  constructor(item) {
    if(item) {
      this.owner_id = userData.ownerId();
      this.name = item.name ? item.name : null,
      this.description = item.description ? item.description : null,
      this._status = item._status ? item._status : false,
      this._secure = item._secure ? item._secure : false,
      this._category = item._category ? item._category : null,
      this._priority = item._priority ? item._priority : 'Low',
      this.localisation = item.localisation ? item.localisation : null,
      this.files = item.files ? item.files : [{ document_id: '' }]
    }
  }
}
