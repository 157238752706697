<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <div>
          <Button @click="$router.push({ path: '/note/add' })" v-if="can('v1.note.create')">
            {{ i18n.$t('Add an Note') }}
          </Button>
        </div>
        <table class="table-auto mt-4 p-mt-4">
          <thead>
            <tr class="border">
              <td><InputText type="checkbox" @change="selectAll" /></td>
              <td>{{ i18n.$t('Date') }}</td>
              <td>{{ i18n.$t('Name') }}</td>
              <td>{{ i18n.$t('Description') }}</td>
              <td>{{ i18n.$t('Priority') }}</td>
              <td>{{ i18n.$t('Status') }}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr class="border" v-for="note in notes" :key="note._id">
              <td>
                <InputText
                  type="checkbox"
                  @change="(e) => onSelect(e, note)"
                  :checked="isChecked(note)"
                />
              </td>
              <td>
                {{ moment.unix(note.created_at).format('YYYY-MM-DD HH:mm') }}
              </td>
              <td>{{ note.name }}</td>
              <td>{{ note.description }}</td>
              <td>{{ note._priority }}</td>
              <td>{{ note._status ? 'Active' : 'No active' }}</td>
              <td></td>
              <td>
                <div class="action">
                  <Button
                    @click="
                      $router.push({
                        path: '/note/view',
                        query: { note: note._id }
                      })
                    "
                    class="p-mr-2"
                  >
                    {{ i18n.$t('View') }}
                  </Button>
                  <Button
                    @click="
                      $router.push({
                        path: '/note/edit',
                        query: { note: note._id }
                      })
                    "
                    v-if="can('v1.note.update')"
                    class="p-mr-2"
                  >
                    {{ i18n.$t('Update') }}
                  </Button>
                  <ButtonConfirm @accept="onDelete(note._id)" v-if="can('v1.note.delete')">
                    <Button> {{ i18n.$t('Delete') }} </Button>
                  </ButtonConfirm>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { FETCH_NOTES, GET_NOTES, GET_USERDATA } from '@/store/types';
import useI18n from '@/plugins/useI18n';
import moment from 'moment';
import { API } from '@/plugins/api';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [{ label: 'My notes', to: '/note' }]
  }),
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const notes = computed(() => store.getters[GET_NOTES]);
    const userData = computed(() => store.getters[GET_USERDATA]);
    const selected = ref([]);
    store.dispatch(FETCH_NOTES, userData.value.id);
    const api = new API();

    function selectAll(e) {
      selected.value = [];
      if (e.target.checked) {
        selected.value = notes.value.map((item) => item._id);
      }
    }

    function popupId(catId) {
      const idx = selected.value.indexOf(catId);
      if (idx !== -1) {
        selected.value.splice(idx, 1);
      }
    }
    function isChecked(note) {
      return selected.value.indexOf(note._id) !== -1;
    }

    function onSelect(e, note) {
      popupId(note._id);
    }

    async function onDelete(id) {
      api.delete('note', id, FETCH_NOTES);
    }

    return { i18n, notes, isChecked, selectAll, onSelect, onDelete, moment };
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
td {
  padding: 5px;
}
.action {
  display: flex;
  justify-content: flex-end;
}
.card {
  padding-top: 0;
}
</style>
