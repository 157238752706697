<template>
  <div class="p-grid note-add">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <h3 class="font-bold">{{ i18n.$t('View note') }}</h3>

      <div class="form-input">
        <p class="">{{ i18n.$t('Name') }}: {{ note.name }}</p>
      </div>
      <div class="form-input p-mt-3">
        <p class="">{{ i18n.$t('Description') }}: {{ note.description }}</p>
      </div>
      <div class="form-input p-mt-3">
        <p class="">{{ i18n.$t('Priority') }}: {{ note._priority }}</p>
      </div>
      <div class="form-input p-mt-3">
        <p class="p-mt-2">
          {{ i18n.$t('Activation') }}:
          {{ note._status ? i18n.$t('Yes') : i18n.$t('No') }}
        </p>
      </div>
      <div class="form-input p-mt-3">
        <p class="p-mt-2">
          {{ i18n.$t('Secure') }}:
          {{ note._secure ? i18n.$t('Yes') : i18n.$t('No') }}
        </p>
      </div>
      <div class="form-input p-mt-3">
        <p class="p-mt-2">
          {{ i18n.$t('Category') }}:
          {{ note._category ? note._category.name : '' }}
        </p>
      </div>
      <div class="form-input p-mt-3">
        <p class="p-mt-2">{{ i18n.$t('Document') }}: {{ note.files }}</p>
      </div>
      <div class="form-input p-mt-3">
        <p class="p-mt-2">{{ i18n.$t('Address') }}: {{ note.localisation }}</p>
      </div>
    </div>
    <Button
      @click="$router.push({ path: '/note' })"
      label="Plain"
      class="p-button p-mt-3"
    >
      <span>{{ i18n.$t('Cancel') }}</span>
    </Button>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from '@/plugins/axios';
import { useStore } from 'vuex';
import useI18n from '@/plugins/useI18n';
import { FETCH_DOCUMENTS, GET_DOCUMENTS, GET_USERDATA } from '@/store/types';
import NoteModel from '@/models/note';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Clients', to: '/contacts' },
      { label: 'My Groups', to: '/groups/list' }
    ]
  }),
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const route = useRoute();
    const eventForm = ref();

    const userData = computed(() => store.getters[GET_USERDATA]);


    const documents = computed(() => store.getters[GET_DOCUMENTS]);
    if (!documents.value) {
      store.dispatch(FETCH_DOCUMENTS, userData.value.id);
    }

    const note = reactive(new NoteModel({}));

    const isLoading = ref(false);

    const query = route.query;
    const eventId = query.note;

    axios.get(`note/${eventId}`).then((response) => {
      const { data } = response.data;
      for (const key in note) {
        note[key] = data[key] ? data[key] : note[key];
      }
      if (documents.value && note.files[0]) {
        documents.value.forEach((el) => {
          if (el._id == note.files[0].document_id) {
            note.files = el.name;
          }
        });
      }
    });

    return {
      i18n,
      eventForm,
      note,
      isLoading
    };
  }
};
</script>

<style>
</style>