<template>
  <Form
    ref="form"
    :item="note"
    title="Add a new note"
    :message="message"
    @submit="onSave"
  />
</template>
<script>
import { ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import axios from '@/plugins/axios';
import Form from '@/components/Note/Form.vue';
import NoteModel from '@/models/note';

export default {
  components: {
    Form
  },
  setup() {
    const router = useRouter(); 
    const note = reactive(new NoteModel({}));
    const form = ref(null);

    async function onSave() {
      form.value.showLoading(true);
      try {
        await axios.post('note', note);
        form.value.getMessage(true);
        setTimeout(() => {
          router.push({ path: `/note` });
        }, 1000);
      } catch (error) {
        console.log('[error]', error);
        form.value.getMessage();
      }
      form.value.showLoading(false);
    }

    return {
      note,
      onSave,
      form
    };
  }
};
</script>
