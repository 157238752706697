<template>
  <div class="p-grid note-add">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card" v-if="item">
        <form class="text-left mt-4">
          <h3 class="font-bold">{{ i18n.$t(title) }}</h3>
          <div class="form-input">
            <p class="">{{ i18n.$t('Name') }}</p>
            <InputText v-model="item.name" required />
          </div>
          <div class="form-input p-mt-3">
            <p class="">{{ i18n.$t('Description') }}</p>
            <Textarea name="description" v-model="item.description" />
          </div>
          <div class="form-input p-mt-3">
            <p class="">{{ i18n.$t('Priority') }}</p>
            <div
              class="radio-input p-mt-2"
              v-for="el in priority"
              :key="el.value"
            >
              <RadioButton
                id="priority"
                name="_priority"
                :value="el.value"
                v-model="item._priority"
                required
              />
              <label for="priority">{{ i18n.$t(el.name) }}</label>
            </div>
            <div class="form-input p-mt-3">
              <p class="p-mt-2">{{ i18n.$t('Activation') }}:</p>
              <div class="radio-input p-mt-2">
                <RadioButton
                  id="no"
                  name="_type"
                  :value="false"
                  v-model="item._status"
                />
                <label for="no">{{ i18n.$t('No') }}</label>
              </div>
              <div class="radio-input p-mt-2">
                <RadioButton
                  id="yes"
                  name="_type"
                  :value="true"
                  v-model="item._status"
                />
                <label for="yes">{{ i18n.$t('Yes') }}</label>
              </div>
            </div>
            <div class="form-input p-mt-3">
              <p class="p-mt-2">{{ i18n.$t('Secure') }}:</p>
              <div class="radio-input p-mt-2">
                <RadioButton
                  id="no"
                  name="_type"
                  :value="false"
                  v-model="item._secure"
                />
                <label for="no">{{ i18n.$t('No') }}</label>
              </div>
              <div class="radio-input p-mt-2">
                <RadioButton
                  id="yes"
                  name="_type"
                  :value="true"
                  v-model="item._secure"
                />
                <label for="yes">{{ i18n.$t('Yes') }}</label>
              </div>
            </div>
            <div class="form-input p-mt-3">
              <p class="p-mt-2">{{ i18n.$t('Category') }}</p>
              <Dropdown
                id="categories"
                :options="categories"
                v-model="item._category"
                optionLabel="name"
                class="border border-gray-500 focus:border-main rounded p-1"
                style="width: 100%"
              ></Dropdown>
            </div>
            <AddDocument :item="item.files[0]" />
            <div class="form-input p-mt-3">
              <p class="p-mt-2">{{ i18n.$t('Address') }}</p>
              <InputGroup
                :invalid="false"
                :validation="false"
                @click="showAddress = true"
              >
                <InputText
                  type="text"
                  v-model="address.fullAddress"
                  name="address"
                />
                <div class="overlay"></div>
                <i class="pi pi-arrow-right" v-if="!valid"></i>
              </InputGroup>
            </div>
            <SelectAddress
              :address="address"
              :country="country"
              @selected="item.localisation = $event"
              v-if="showAddress"
            />
          </div>
          <div class="form-input">
            <transition-group name="p-messages" tag="div">
              <Message
                v-for="msg of message"
                :severity="msg.severity"
                :key="msg.content"
              >
                {{ msg.content }}
              </Message>
            </transition-group>
          </div>
          <div class="form-input action p-mt-4">
            <Button
              @click="$router.push({ path: '/note' })"
              label="Plain"
              class="p-button"
            >
              <span>{{ i18n.$t('Cancel') }}</span>
            </Button>
            <Button type="button" class="loading-btn" @click="$emit('submit')">
              <spinner v-if="isLoading" color="white" />
              <span v-if="!isLoading">{{ i18n.$t('Save') }}</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
import AddDocument from '@/components/Information/AddDocument.vue';
import SelectAddress from '@/components/Register/SelectAddress.vue';
import InputGroup from '@/components/UI/InputGroup.vue';
import { ref, reactive, onMounted } from 'vue';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'Contacts', to: '/contacts' },
      { label: 'Groups', to: '/groups/list' }
    ]
  }),
  props: {
    item: {
      type: Object
    },
    message: {
      type: Array
    },
    title: {
      type: String
    }
  },
  components: {
    Spinner,
    AddDocument,
    SelectAddress,
    InputGroup
  },
  setup(props) {
    const { i18n } = useI18n();

    const showAddress = ref(false);

    const country = ref('');

    const address = reactive({
      addressReq: '',
      fullAddress: props.item.localisation ? props.item.localisation : ''
    });
    const categories = [
      { name: 'Private' },
      { name: 'Work' },
      { name: 'Family' }
    ];

    const priority = [
      { name: 'Low', value: 'Low' },
      { name: 'Medium', value: 'Medium' },
      { name: 'Hight', value: 'Hight' }
    ];

    const isLoading = ref(false);

    function showLoading(type) {
      isLoading.value = type;
    }

    const message = ref([]);

    function getMessage(type) {
      message.value = [];
      if (type) {
        message.value = [
          { severity: 'success', content: i18n.$t('Saved successfully') }
        ];
      } else {
        message.value = [
          { severity: 'error', content: i18n.$t('Oops, something went wrong!') }
        ];
      }
    }
    return {
      country,
      address,
      showAddress,
      categories,
      i18n,
      getMessage,
      isLoading,
      showLoading,
      priority
    };
  }
};
</script>

<style lang="scss" scoped>
.note-add {
  p {
    margin-bottom: 0.25rem;
  }
  .radio-input {
    label {
      margin-left: 0.5rem;
      position: relative;
      top: -2px;
    }
  }
}
.action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .p-button {
    height: 35px;
  }
}
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  label,
  p {
    margin-bottom: 0.5rem;
  }
}
.loading-btn {
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.radio-input label {
  margin-left: 10px;
}
.file-wrapper {
  position: relative;
  text-align: center;
  display: table-cell;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
}
.file-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
span {
  width: 100%;
}
.action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .p-button {
    height: 35px;
  }
}
</style>