<template>
  <div class="form-input p-mt-3">
    <div class="p-d-flex p-jc-between p-ai-center p-mb-1">
      <label for="files">Document</label>
      <Button type="button" class="p-ml-2" @click="showModal = true">
        Add Document
      </Button>
    </div>
    <Dropdown
      name="files"
      v-model="item.document_id"
      :options="documents"
      optionLabel="name"
      optionValue="_id"
      class="dropdown"
    />
  </div>
  <Dialog
    header="Add Document"
    v-model:visible="showModal"
    :style="{ width: '40vw' }"
    :modal="true"
  >
    <AddDocumentModal @onuploaddocument="onUploadDocument" />
  </Dialog>
</template>

<script>
import AddDocumentModal from '@/components/Information/AddDocumentModal.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { FETCH_DOCUMENTS, GET_DOCUMENTS, GET_USERDATA } from '@/store/types';

export default {
  components: {
    AddDocumentModal
  },
  props: {
    item: {
      type: Object
    }
  },
  setup(props) {
    const store = useStore();
    const showModal = ref(false);
    const files = ref(null);

    const userData = computed(() => store.getters[GET_USERDATA]);

    const documents = computed(() => store.getters[GET_DOCUMENTS]);
    if (!documents.value || documents.value.length == 0) {
      store.dispatch(FETCH_DOCUMENTS, userData.value.id);
    }
    const onUploadDocument = async (doc) => {
      await store.dispatch(FETCH_DOCUMENTS, userData.value.id);
      props.item.document_id = doc.data._id;
      showModal.value = false;
    };

    return {
      onUploadDocument,
      documents,
      showModal
    };
  }
};
</script>

<style scoped>
.p-dialog .form-input {
  max-width: 100%;
}
.dropdown {
  width: 100%;
}
</style>