<template>
  <Form
    ref="form"
    :item="note"
    title="Edit note"
    :message="message"
    @submit="onSave"
  />
</template>

<script>
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from '@/plugins/axios';
import Form from '@/components/Note/Form.vue';
import NoteModel from '@/models/note';
import { API } from '@/plugins/api';
export default {
  components: {
    Form
  },
  setup() {
    const route = useRoute();

    const note = reactive(new NoteModel({}));

    const eventId = route.query.note;

    axios.get(`note/${eventId}`).then((response) => {
      const { data } = response.data;
      for (const key in note) {
        note[key] = data[key] ? data[key] : note[key];
      }
    });

    const api = new API();
    async function onSave() {
      const routePath = { path: '/note' };
      api.update('note', 'Note', eventId, note, routePath);
    }

    return {
      note,
      onSave
    };
  }
};
</script>