<template>
  <form ref="documentform" enctype="multipart/form-data" @submit="onSubmit">
    <div class="form-input">
      <label for="name" class="mr-4 font-semibold">{{ i18n.$t('Name') }}</label>
      <InputText
        type="text"
        name="modal_name"
        v-model="modal_name"
        :class="{ 'p-error': !!errors.modal_name }"
      />
    </div>
    <div class="form-input">
      <label for="description" class="mr-4 font-semibold">{{
        i18n.$t('Description')
      }}</label>
      <Textarea
        name="modal_description"
        v-model="modal_description"
        :autoResize="true"
        rows="3"
        cols="30"
        :class="{ 'p-error': !!errors.modal_description }"
      />
    </div>

    <input type="hidden" name="modal_type" value="file" />

    <div class="form-input">
      <label for="category" class="mr-4 font-semibold">Category:</label>
      <Dropdown
        name="category"
        v-model="category"
        :options="categories"
        optionLabel="name"
        optionValue="code"
        :class="{ 'p-error': !!errors.type }"
      />
      <input type="hidden" name="modal_category" v-model="category" />
    </div>
    <div class="form-input">
      <FileUpload
        auto
        name="modal_docupload"
        :disabled="uploadDisabled"
        :customUpload="true"
        @uploader="onFileUpload"
        :accept="`image/*,application/pdf`"
        :maxFileSize="9000000"
        :uploadLabel="uploadLabel"
        :showUploadButton="false"
      >
        <template #empty>
          <p>{{ i18n.$t('Drag and drop files to here to upload') }}</p>
        </template>
      </FileUpload>
    </div>
    <input type="hidden" name="owner_id" v-model="owner_id" />
    <button type="submit" ref="submitBtn" class="invisible">
      {{ i18n.$t('Submit') }}
    </button>
    <div v-if="showResult" class="mt-4">
      <p v-if="isError" class="font-semibold text-red-600">
        {{ i18n.$t('Oops, something went wrong!') }}
      </p>
      <p v-if="!isError" class="font-semibold text-green-600">
        {{ i18n.$t('Saved successfully') }}
      </p>
    </div>
  </form>
</template>

<script>
import { ref, computed } from 'vue';
import { useField, useForm } from 'vee-validate';
import useI18n from '@/plugins/useI18n';
import * as yup from 'yup';
import axios from '@/plugins/axios';
import FileUpload from 'primevue/fileupload';
import { GET_USERDATA } from '@/store/types';
import { useStore } from 'vuex';
export default {
  name: 'MyInput',
  components: {
    FileUpload
  },
  setup(props, { emit }) {
    const { i18n } = useI18n();
    const documentform = ref();
    const submitBtn = ref();
    const showResult = ref(false);
    const isError = ref(false);
    const uploadDisabled = ref(false);
    const uploadLabel = ref('Upload');
    const store = useStore();
    const userData = computed(() => store.getters[GET_USERDATA]);

    const owner_id = userData.value.id;
    let selectedFiles = null;

    const categories = [
      { name: 'Information', code: 'information' },
      { name: 'Services', code: 'services' }
    ];

    const { handleSubmit, errors } = useForm({
      validationSchema: yup.object().shape({
        modal_name: yup.string().required(),
        modal_description: yup.string().required(),
        category: yup.string().required()
      })
    });

    const { value: modal_name } = useField(
      'modal_name',
      yup.string().required()
    );
    const { value: modal_description } = useField(
      'modal_description',
      yup.string().required()
    );
    const { value: category } = useField('category', yup.string().required());
    category.value = 'information';
    const onSubmit = handleSubmit(async () => {
      const formdata = new FormData(documentform.value);
      const formDataUpload = new FormData();
      for (const pair of formdata.entries()) {
        const key = pair[0].includes('modal_')
          ? pair[0].replace('modal_', '')
          : pair[0];
        formDataUpload.append(key, pair[1]);
      }
      formDataUpload.append('file', selectedFiles);
      showResult.value = false;
      uploadLabel.value = 'Uploading...';
      uploadDisabled.value = true;
      try {
        const res = await axios.post(`document`, formDataUpload);
        isError.value = false;
        emit('onuploaddocument', res.data);
      } catch (error) {
        console.log('[error]', error);
        isError.value = true;
      }
      showResult.value = true;
      uploadDisabled.value = false;
      uploadLabel.value = 'Upload';
    });

    function onFileUpload(event) {
      selectedFiles = event.files[0];
      submitBtn.value.click();
    }

    return {
      documentform,
      submitBtn,
      modal_name,
      modal_description,
      category,
      categories,
      errors,
      owner_id,
      onFileUpload,
      onSubmit,
      uploadDisabled,
      uploadLabel,
      showResult,
      isError,
      i18n
    };
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  margin-top: 20px;
}
.form-input label {
  margin-bottom: 0.5rem !important;
}
.form-action {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.form-action button {
  height: 40px;
}
.add-btn {
  width: 120px;
}
</style>
